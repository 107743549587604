/* Mobile */
$mobileWidth: 21.9375rem;

/* Tablet */
$tabletWidth: 4.75rem;
$tabletLeftVerticalMargin: 1rem;
$tabletRightMargin: 2.5rem;

/* Desktop */
$desktopWidth: 14.6875rem;
$desktopLeftVerticalMargin: 1rem;
$desktopRightMargin: 6rem;
