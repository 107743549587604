@use 'colors' as colors;

.mat-progress-bar-buffer {
  background-color: white;
}

.mat-progress-bar {
  border-radius: 2px;
}

.mat-progress-bar--success .mat-progress-bar-fill::after {
  background-color: colors.$success;
}

.mat-progress-bar--error .mat-progress-bar-fill::after {
  background-color: colors.$error;
}

.mat-progress-bar--warning .mat-progress-bar-fill::after {
  background-color: colors.$warning;
}
