@use 'sass:color';
@use './cdk-overlay';
@use './mat-icon';
@use './mat-dialog';
@use './mat-form-field';
@use './mat-chip';
@use './mat-checkbox';
@use './mat-select';
@use './mat-slide-toggle';
@use './mat-snack-bar';
@use './mat-progress-bar';
@use './mat-tooltip';
@use './mat-spinner';

.wiz-backdrop {
  background-color: rgb(33 26 47 / 70%);
}
