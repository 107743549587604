@use 'colors' as colors;

.ngx-slider {
  .ngx-slider-selection {
    background-color: colors.$primary !important;
  }

  .ngx-slider-tick {
    height: 0.625rem !important;
    width: 0.125rem !important;
    background-color: colors.$primary !important;
  }

  .ngx-slider-bar {
    background-color: #e5e4ff !important;
    height: 0.375rem !important;

    &.ngx-slider-selection {
      background-color: colors.$primary !important;
    }
  }
  .ngx-slider-pointer {
    width: 1.375rem !important;
    height: 1.375rem !important;
    background-color: colors.$primary !important;
    top: auto !important;
    bottom: -0.8125rem !important;
    border: 0.25rem solid white !important;

    &::after {
      display: none !important;
    }
  }

  .ngx-slider-bubble {
    font-size: 0.875rem !important;
    font-weight: bold !important;
    color: colors.$textColor !important;

    &.ngx-slider-limit {
      font-weight: normal !important;
      color: colors.$disabled !important;
      font-size: 0.6875rem !important;
    }
  }

  &--disabled-untouched {
    .ngx-slider-bar.ngx-slider-selection,
    .ngx-slider-pointer {
      background-color: #cfcdff !important;
    }
    .ngx-slider-bubble {
      font-weight: normal !important;
      color: colors.$disabled !important;
      font-size: 0.6875rem !important;
    }
  }
  &--error {
    .ngx-slider-bar.ngx-slider-selection,
    .ngx-slider-pointer {
      background-color: colors.$error !important;
    }
    .ngx-slider-bubble:not(.ngx-slider-limit) {
      color: colors.$error !important;
    }

    .ngx-slider-tick {
      display: none;
    }
  }
}
