@use 'colors' as colors;
// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type
.wzb-ckeditor {
  .ck-editor__editable_inline {
    box-sizing: border-box !important;
    min-height: 6.25rem !important;
    border-radius: 10px !important;
    border: 1px solid colors.$borderColor !important;
    padding: 0 1rem !important;
    margin-top: 0.5rem !important;
    max-width: calc(100vw - 2.5rem) !important;
    font-size: 0.875rem !important;
  }

  .ck-placeholder {
    font-size: 1rem !important;
  }

  .ck-focused {
    border: 2px solid colors.$primary !important;
    box-shadow: none !important;
    outline: #e6e7f3 auto 0.0625rem !important;
    outline-offset: 0.3125rem !important;
  }

  .ck-toolbar {
    border-radius: 10px !important;
    border: 1px solid #e6e7f3 !important;
    padding: 0 2rem !important;
    max-width: 99% !important;
  }

  .ck-toolbar_vertical {
    border-radius: 0 !important;
    padding: 0 !important;
  }

  .ck-list-styles-dropdown > .ck-dropdown__panel > .ck-toolbar {
    border-radius: 0 !important;
    padding: 0 !important;
  }

  .ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .ck.ck-editor__editable > .ck-placeholder::before {
    color: black;
  }

  &--error {
    .ck-editor__editable_inline {
      border: 2px solid colors.$error !important;
    }
  }

  .ck-read-only {
    color: colors.$disabled;
  }
}
