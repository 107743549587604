@use 'colors' as colors;

.custom-chips {
  .mat-standard-chip {
    color: colors.$textColor !important;
    background-color: colors.$primaryLight !important;
  }

  .mat-chip-list-wrapper {
    min-height: 2.5rem !important;
  }

  .mat-form-field-infix {
    padding: 1rem 0 0.5rem !important;
  }

  .button-remove__icon {
    height: 0.75rem !important;
  }
}
