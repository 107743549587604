$defaultBg: #fafbff;
$primary: #8683ff; // "parme"
$bubbleGum: #f29fff;
$parme15: rgb(134 131 255 / 15%);
$bubbleGum15: rgb(242 159 255 / 15%);
$primaryLight: #8683ff19;
$primaryLighter: #8683ff66;
$success: #1dd090;
$successLight: #e8faf4;
$error: #ff5459;
$errorLight: #fcf0ed;
$warning: #e3ba1f;
$warningLight: #f8c60b19;
$grey: #b3b3bf;
$greyLight: #f2f2f4;
$dark: black;
$textColor: #000028;
$borderColor: #d1e1f5;
$disabled: #9999a9;

// BOX-SHADOW COLORS
$box-shadow-dark-20: rgba($dark, 0.2);
