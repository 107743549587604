@use 'colors' as colors;

.mat-tooltip {
  max-width: 34.375rem !important;
  padding: 1.125rem !important;
  color: colors.$textColor !important;
  background-color: white;
  line-height: 1.57;
  font-size: 0.875rem;
  box-shadow: 0 1rem 1.5rem rgba(96 97 112 / 16%);
  position: relative;
  overflow: visible !important;

  &::before {
    position: absolute;
    content: ' ';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid white;
    bottom: -0.5rem;
    right: 50%;
  }
}
