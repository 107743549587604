@use 'colors' as colors;
:root {
  --mat-option-selected-state-label-text-color: #000028;
  --mat-option-selected-state-layer-color: white;
  --mat-dialog-container-max-width: 100vw;
  --mdc-outlined-text-field-disabled-outline-color: #d1e1f5;
}

.mat-mdc-select-panel {
  border-radius: 10px !important;
  margin-top: 0.5rem;
  margin-left: -1rem;
  padding: 0 !important;
}

.commitee-select {
  --mat-select-trigger-text-weight: 600;

  &.mat-mdc-select {
    margin-bottom: 1.5rem;
    padding: 1rem 0.875rem;
    border: solid 1px colors.$borderColor;
    border-radius: 10px;
    max-width: 9.375rem;
  }

  &.mat-mdc-option {
    padding: 0.75rem 0.9375rem !important;
    margin: 0;
    line-height: 1.5 !important;
    word-wrap: break-word;
    white-space: normal;
    font-size: 1rem !important;
    height: unset;
    min-height: unset !important;

    &:not(:last-child) {
      margin-bottom: 0.3125rem;
    }

    &.mdc-list-item__primary-text {
      font-weight: 600 !important;
    }

    &.mdc-list-item--selected {
      --mat-option-label-text-weight: 600;
    }

    .mat-option-multiple {
      background-color: transparent !important;
    }
  }
}
