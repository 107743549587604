/* stylelint-disable selector-max-specificity, max-nesting-depth */

@use 'colors' as colors;

:root {
  .mat-mdc-slide-toggle {
    .mdc-switch {
      --mdc-switch-track-width: 3.125rem;
      --mdc-switch-track-height: 1.875rem;
      --mdc-switch-track-shape: 1.875rem;
      --mdc-switch-handle-height: 1.25rem;
      --mdc-switch-handle-width: 0.25rem;
      --mdc-switch-selected-track-color: #8683ff;
      --mdc-switch-selected-focus-track-color: #8683ff;
      --mdc-switch-selected-hover-track-color: #8683ff;
      --mdc-switch-selected-pressed-track-color: #8683ff;
      --mdc-switch-unselected-track-color: #ff5459;
      --mdc-switch-unselected-hover-track-color: #ff5459ed;
      --mdc-switch-unselected-focus-track-color: #ff5459;
      --mdc-switch-unselected-pressed-track-color: #ff5459;

      .mdc-switch__handle {
        width: 0.25rem;

        &::before {
          margin-left: 0.6rem;
          border-radius: 2px;
        }
        &::after,
        .mdc-switch__shadow,
        .mdc-switch__icons {
          display: none;
        }
      }

      .mat-slide-toggle-thumb-container {
        top: 0.313rem;
        left: 0.313rem;
      }

      &--checked {
        .mdc-switch__track {
          &::before {
            background-color: green !important;
          }
        }

        .mdc-switch__handle-track {
          transform: translate3d(1.25rem, 0, 0) !important;
        }

        .mdc-switch__handle {
          &::before {
            border-radius: 50%;
            width: 1.25rem;
            margin-left: 0;
          }
        }
      }
    }
  }
}
