/* stylelint-disable selector-max-specificity */

@use 'colors' as colors;

:root {
  --mdc-outlined-text-field-container-shape: 0.625rem;
  --mdc-shape-small: 0.625rem;
  --mat-form-field-disabled-trailing-icon-color: rgb(0 0 0 / 38%);
  --mdc-outlined-text-field-label-text-color: #000028;

  .mat-mdc-form-field-icon-suffix {
    padding: 0 0.625rem 0 0.25rem;
  }

  .mat-mdc-form-field.mat-form-field-appearance-outline {
    width: 100%;
  }

  .mat-mdc-floating-label {
    font-weight: normal;
    font-size: 1rem;
    color: colors.$textColor;
  }

  .mat-icon {
    width: 1.125rem;
    height: 1.125rem;
  }
}
