@use 'colors' as colors;

.mat-mdc-checkbox-layout {
  white-space: normal !important;
}

.mat-mdc-checkbox-inner-container {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 6px;
  margin-right: 0.7rem !important;
}

.mat-mdc-checkbox-frame {
  background-color: colors.$borderColor !important;
  border: none !important;
  border-radius: 6px !important;
}

.mat-mdc-checkbox-checked {
  .mat-mdc-checkbox-frame,
  .mat-mdc-checkbox-inner-container {
    width: 1.25rem;
    height: 1.25rem;
    border-width: 3px;
  }

  .mat-mdc-checkbox-background {
    background-color: colors.$primary !important;
    border-radius: 6px !important;
  }
}

.mdc-checkbox-disabled {
  .mat-checkbox-background {
    background-color: colors.$disabled !important;
    border-radius: 6px !important;
  }
}

.mat-mdc-pseudo-checkbox {
  background-color: colors.$borderColor !important;
  border-color: transparent !important;
  border-radius: 6px !important;
}

.mat-mdc-pseudo-checkbox-checked {
  background-color: colors.$primary !important;
}
