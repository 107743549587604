@use 'breakpoints' as br;
@use 'colors' as colors;

.header {
  --flex-direction: column;
  --align-items: center;

  display: flex;
  flex-direction: var(--flex-direction);
  justify-content: space-between;
  align-items: var(--align-items);

  &__page-title {
    --justify-content: center;
    --margin: 0.5rem 0 1.5rem;

    display: flex;
    justify-content: var(--justify-content);
    margin: var(--margin);
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5;

    &__chip {
      display: flex;
      margin: 0 0 0 1rem;
      vertical-align: middle;
      font-weight: 600;
    }

    &__icon {
      margin: 0 0 0 0.3rem;
      max-width: 1rem !important;
      max-height: 1rem !important;

      &--primary {
        color: colors.$primary;
      }
      &--success {
        color: colors.$success;
      }
      &--warning {
        color: colors.$warning;
      }
    }
  }

  @include br.tabletPortrait {
    --flex-direction: row;
    --align-items: center;
  }

  @include br.webOrTabletLandscape {
    --flex-direction: row;
    --align-items: center;
    &__page-title {
      --justify-content: flex-start;

      margin: 0 0 1.875rem;
    }
  }
}
