@use 'colors' as colors;
@use 'breakpoints' as br;

.form-controls {
  display: flex;
  margin-bottom: 1.5rem !important;
  align-items: center;

  &__btn {
    display: flex;

    &__submit--gap {
      margin-left: 1.25rem !important;
    }

    &__cancel {
      margin-right: 1rem !important;
    }

    &--disabled {
      background-color: colors.$primaryLight;
    }
  }

  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem !important;
    padding: 0.5rem 1rem !important;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    font-style: normal;

    &__icon {
      display: flex;
      margin-right: 0.75rem;
      border-radius: 100%;
      vertical-align: center;
      min-width: 1.125rem;
      height: 1rem;

      &--success {
        color: colors.$success;
      }

      &--error {
        color: colors.$error;
      }

      &--info {
        color: colors.$warning;
      }
    }

    &--success {
      background-color: colors.$successLight;
    }

    &--error {
      background-color: colors.$errorLight;
    }

    &--info {
      background-color: colors.$warningLight;
    }
  }

  @include br.handset {
    position: fixed !important;
    bottom: 6rem !important;
    z-index: 99;

    &__btn {
      display: flex;
      margin: 0 auto !important;
      gap: 1rem;

      &__submit,
      &__cancel {
        width: 100%;
      }
    }
    &__message {
      right: 0;
    }
  }
}
