@use 'colors' as colors;
@use 'breakpoints' as br;

.mat-snack-bar-container {
  display: flex;
  background-color: white;
  color: colors.$textColor;
  border-radius: 16px !important;
  margin: 0;
  padding: 0 !important;
  position: absolute;
  bottom: 2rem;
  left: 2rem;

  @include br.handsetPortrait {
    width: auto !important;
    margin: 1rem;
    left: auto;
  }
}
