/* stylelint-disable unit-disallowed-list */
.cdk-global-scrollblock {
  // Fix position fixed on IOS 15
  position: sticky !important;
}

.cdk-global-overlay-wrapper {
  @media only screen and (1280px <= width <= 1440px) {
    top: 10px;
  }
}
