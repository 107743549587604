@use 'variables' as var;
@use 'colors' as colors;
@use 'breakpoints' as br;

.href-clean {
  text-decoration: none;
  color: colors.$textColor;
  cursor: pointer;
  transition: transform 0.15s ease-out;

  &:hover {
    transform: scale(1.02);
  }
}

.btn-clean {
  display: inline-block;
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  appearance: none;
  transition: transform 0.15s ease-out;

  &:hover:not([disabled]) {
    transform: scale(1.02);
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

@mixin btn-emphasis($background, $color) {
  display: flex;
  place-content: center center;
  color: $color !important;
  background-color: $background !important;
  border-radius: 10px !important;
  padding: 0.75rem 1.25rem !important;
  font-weight: 600;
  font-size: 1rem;
  font-style: normal;

  &__icon--left {
    vertical-align: middle;
    color: $color;
    max-width: 0.8125rem !important;
    max-height: 0.8125rem !important;
    margin-right: 0.8125rem;
  }

  &__icon--left--big {
    vertical-align: middle;
    color: $color;
    max-width: 1rem !important;
    max-height: 1.25rem !important;
    margin-right: 0.8125rem;
  }

  &__icon--right {
    vertical-align: middle;
    color: $color;
    max-width: 0.8125rem !important;
    max-height: 0.8125rem !important;
    margin-left: 1rem;
  }

  &:disabled {
    background-color: colors.$greyLight !important;
    color: colors.$grey !important;
    box-shadow: none;
  }
}

%box-shadow-default {
  box-shadow: 0 0.375rem 0.9375rem rgb(0 0 40 / 20%);
}

%box-shadow-light {
  box-shadow: 0 0.625rem 1.875rem 0.625rem rgb(0 0 40 / 5%);
}

.btn-emphasis-primary {
  @include btn-emphasis(#8683ff, white);
  @extend %box-shadow-default;
}

.btn-emphasis-white {
  @include btn-emphasis(white, colors.$primary);
  @extend %box-shadow-default;
}

.btn-emphasis-ghost {
  @include btn-emphasis(white, colors.$primary);

  border: 1px solid colors.$primary;
}

.btn-emphasis-grey {
  @include btn-emphasis(colors.$greyLight, colors.$grey);
  @extend %box-shadow-default;
}

.btn-emphasis-home-contact {
  @include btn-emphasis(white, colors.$textColor);

  border: 1px solid colors.$textColor;

  &__icon--right {
    max-width: 1.25rem !important;
    max-height: 1.25rem !important;
  }
}

.btn-emphasis-modal-primary {
  @include btn-emphasis(colors.$primary, white);
  @extend %box-shadow-default;
}

@mixin btn-default($background, $color) {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color !important;
  background-color: $background !important;
  border-radius: 8px !important;
  padding: 0.5rem 1rem !important;
  font-weight: 600;
  font-size: 0.875rem;
  text-decoration: none;

  &__icon {
    width: 0.8438rem !important;
    height: 0.8438rem !important;
    margin-right: 0.375rem;
  }

  &:disabled {
    background-color: colors.$greyLight !important;
    color: colors.$grey !important;
    box-shadow: none;

    &:hover {
      transform: none;
      cursor: default;
    }
  }

  @include br.handset {
    min-width: 9rem;
    padding: 0.7rem 0.5rem;
  }
}

.btn-default-primary {
  @include btn-default(colors.$primary, white);
  @extend %box-shadow-default;
}

.btn-default-white {
  @include btn-default(white, colors.$primary);
  @extend %box-shadow-default;
}

.btn-default-ghost-primary {
  @include btn-default(transparent, colors.$primary);

  border: 1px solid colors.$primary;
}

.btn-default-ghost-error {
  @include btn-default(transparent, colors.$error);

  border: 1px solid colors.$error;
}

@include br.handset {
  .btn-create-action {
    width: 2rem;
    height: 2rem;
    padding: 1rem !important;
    min-width: unset;
    border-radius: 50%;
    position: fixed !important;
    bottom: 7.75rem !important;
    right: 1rem !important;

    &__legend {
      display: none;
    }

    &__icon {
      max-width: 100% !important;
      max-height: 100% !important;
      padding: 0.35rem !important;
      margin: 0 !important;
    }
  }
}
