@use 'variables' as var;
@use 'colors' as colors;

@mixin chip($color, $background) {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 20px;
  color: $color;
  background-color: $background;
  padding: 0.375rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;

  &__icon {
    display: flex;
    width: 0.875rem !important;
    height: 0.875rem !important;
    margin-right: 0.3125rem !important;
  }
}

@mixin chip-light($color, $background) {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 20px;
  color: colors.$textColor;
  background-color: $background;
  padding: 0.375rem 0.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  &__icon {
    display: flex;
    width: 0.875rem !important;
    height: 0.875rem !important;
    margin-right: 0.3125rem !important;
    color: $color;
  }
}

.chip--primary {
  @include chip(colors.$primary, colors.$primaryLight);
}

.chip-light--primary {
  @include chip-light(colors.$primary, colors.$primaryLight);
}

.chip-light--success {
  @include chip-light(colors.$success, colors.$successLight);
}

.chip-light--warning {
  @include chip-light(colors.$warning, colors.$warningLight);
}

.chip-light--error {
  @include chip-light(colors.$error, colors.$errorLight);
}

.chip-light--grey {
  @include chip-light(colors.$grey, colors.$greyLight);
}
