@use 'variables' as var;
@use 'breakpoints' as br;
@use 'colors' as colors;
@use 'theme';
@use 'override/material';
@use 'override/ckeditor/ckeditor';
@use 'btn';
@use 'card';
@use 'navbar';
@use 'chip';
@use 'header';
@use 'form-controls';
@use 'slider';

html {
  color: colors.$textColor;
  background-color: colors.$defaultBg;
  font-family: var.$font-family;
  font-weight: normal;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  overflow: visible !important;
}

body {
  margin: 0;
  padding-right: 0 !important;
  overflow-x: hidden;
  color: colors.$textColor;
  background-color: colors.$defaultBg;
}

code {
  white-space: pre-wrap;
}

p {
  margin: 0;
}

.ul-clean {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.accessibility-off {
  *:focus {
    outline: none !important;
  }
}

.ng-scroll-content {
  max-width: 100%;
  min-width: auto;
}
